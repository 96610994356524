import React, { useContext } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import Logo from '../../../components/common/Logo';
import LogoAnimation from '../../../components/common/LogoAnimation';
import { pagesVariants } from '../data/pageVariants';
import AppContext from '../../../context/Context';
import usePrayerTimesPresentation from '../hooks/usePrayerTimesPresentation';
import PrayerTimesPage from '../components/presentation/pageVariants/PrayerTimesPage';
import FullImage from '../components/presentation/pageVariants/FullImage';
import ImageLeftTextight from '../components/presentation/pageVariants/ImageLeftTextRight';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import ImageRightTextLeft from '../components/presentation/pageVariants/ImageRightTextLeft';
import OnlyText from '../components/presentation/pageVariants/OnlyText';

const Presentation = () => {
  const { organizationId, id } = useParams();

  let {
    config: { isDark }
  } = useContext(AppContext);
  const { organizationData, prayerTimes, pages, loading } =
    usePrayerTimesPresentation(organizationId, id);

  const handleOnNewDay = () => {
    window.location.reload();
  };

  // hijriEn={`${prayerData.date.hijri.weekday.en}, ${prayerData.date.hijri.day} ${prayerData.date.hijri.month.en} ${prayerData.date.hijri.year} `}
  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className={'justify-content-center'}>
        <Col xs={'auto'}>
          <Logo
            width={150}
            widthSm={80}
            srcLight={organizationData.img_light}
            srcDark={organizationData.img_dark}
          />
        </Col>
      </Row>
      <Carousel data-bs-theme="light" indicators={false} controls={false}>
        {pages.map(page => {
          return (
            page.active && (
              <Carousel.Item
                key={`presentation-page-${page.id}`}
                interval={page.interval}
              >
                {page.variant === pagesVariants.prayerTimes && (
                  <PrayerTimesPage
                    prayerTimes={prayerTimes}
                    onNewDay={handleOnNewDay}
                  />
                )}
                {page.variant === pagesVariants.appLogo && (
                  <LogoAnimation style={{ width: '40%' }} loop />
                )}
                {page.variant === pagesVariants.fullImage && (
                  <FullImage page={page} />
                )}
                {page.variant === pagesVariants.imageLeftTextRight && (
                  <ImageLeftTextight page={page} />
                )}
                {page.variant === pagesVariants.imageRightTextLeft && (
                  <ImageRightTextLeft page={page} />
                )}
                {page.variant === pagesVariants.onlyText && (
                  <OnlyText page={page} />
                )}
              </Carousel.Item>
            )
          );
        })}
      </Carousel>
    </>
  );
};

export default Presentation;
